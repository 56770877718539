@font-face {
  font-family: "circular";
  src: url("../src/fonts/circular/CircularStd-Book.otf");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: 0;
  font-family: "circular";
}


.auth-page {
  background-color: #f6f6f6;
}

.add-pickup-header {
  border-radius: 50px 50px 0 0;

}

.view-all-btn {
  color: #6f2da8;
  font-weight: 700;
  background-color: #efefef;
  padding: 10px 15px;
  border-radius: 5px;
}

.pending {
  background-color: #EACCFF;
  padding: 1px 10px;
  border-radius: 2px;
  color: rgba(154, 8, 255, 1);
  width: 120px;
  border-radius: 5;
  text-align: center;
  border-radius: 10px;

}

.assigned {
  width: 120px;
  background-color: #f7ebd8d0;
  padding: 1px 10px;
  border-radius: 2px;
  color: #fca400;
  text-align: center;
  border-radius: 10px;
}

.completed {
  width: 120px;
  background-color: #eaf8f1;
  padding: 1px 10px;
  border-radius: 2px;
  color: #00883b;
  text-align: center;
  border-radius: 10px;
}

.accepted {
  width: 120px;
  background-color: #E1EAF8;
  padding: 1px 10px;
  border-radius: 2px;
  color: rgba(79, 146, 255, 1);
  text-align: center;
  border-radius: 10px;
}

/* .primary-btn {
  background-color: #6f2da8;
  height: 3.5vh;
  border: none;
  padding: 8px 20px;
  color: #fff;
  border-radius: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .primary-btn-outline {
  border: 1px solid #6f2da8;
  background-color: #fff;
  padding: 5px 10px;
  color: #6f2da8;
  border-radius: 5px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.input-field-style {
  display: flex;
  align-items: center;
  border: 1px solid #efefef;
  border-radius: 5px;
  height: 45px;
  padding: 0 5px;
  background-color: #fff;
  margin-top: 0;

}


#photo-input {
  display: none;
}

.upload-photo-icon {
  font-size: 96px;
  font-variation-settings: 'OPSZ' 96, 'FILL' 0.0;
}

.vehicle-photo {
  border-radius: 15%;
  width: 160px;
  height: 120px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #efefef;
  margin-bottom: 10px;

}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  background-color: #00883b;
}

.custom-carousel {
  max-width: 300px !important;
  max-height: 280px !important;
}

.carousel-modal {
  width: 550px;
  height: 400px;
}

.custom-file-input::before {
  content: 'Select some files';
  display: inline-block;
  background-color: #ce9ff780;
  border: 1px solid #6f2da8;
  border-radius: 3px;
  padding: 10px 28px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
}

.input-date {
  outline: none;
  border: none;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.cercle-btn {
  height: 44px !important;
}

.input-style {
  background-color: #fff;
  width: 100%;
  padding: 10px 15px;
  height: 54px !important;
  border-radius: 4px;
  border: 1px solid #e8e8e8 !important;
  outline: none;
}

.aside-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 93vh;
  align-items: center;
  width: 5rem;
}

.overlay {
  height: 100%;
  width: 100%;
  padding-top: 6.5vh;
  display: flex;
}

.header {
  height: 7vh;
  position: fixed;
  z-index: 2;
}

.forbidden-card {
  width: 35%;
  height: 40%;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  box-shadow: 2px 1px 2px 0 #dddddd;
  background: #fcfafa;
}

.large-size {
  font-size: 1.6rem;
}

.normal-size {
  font-size: 1.2rem;
}

.cercle-btn {
  height: 35px !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

.forbidden-card>div {
  width: 100%;
  border-radius: 10px 10px 0 0;
  background-color: #f65656;
}

.overlay aside {
  margin-top: 1px;
  height: 100vh;
  box-shadow: 0 7px 20px 0 #dddddd;
  position: fixed;
  background-color: #fff;
}

aside::-webkit-scrollbar {
  width: 0.4em;
}

aside::-webkit-scrollbar-thumb {
  background-color: #b289d5;
  outline: 1px solid #f7f7f7;
  border-radius: 20px;
}

.aside-list-open {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 93vh;
  align-items: center;
  width: 13rem;
}

.hover:hover {
  cursor: pointer;
}

.aside-items-list li {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
  margin: 5px 0;
  color: #25174d;
  gap: 10px;
  font-size: 1.2rem;
  font-weight: 500;
}

.aside-items-list {
  width: 100%;
}

.aside-items-list li:hover {
  background-color: #6f2da8;
  color: #fff;
  cursor: pointer;
}

.overlay section {
  padding: 15px 1.5rem;
  width: 100%;
  padding-left: 6.5rem;
  background-color: #f5f5f5;
  height: 100%;
  min-height: 100vh;
}

.overlay .opne-asid {
  padding-left: 14.5rem;
}

.active-aside-items-list {
  background-color: #6f2da8;
  color: #fff !important;
}

.user-profile {
  height: 100%;
  border-left: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 15vw;
}

.user-profile-drop-down {
  width: 15vw !important;
  top: -3rem;
  right: 5rem;
  padding: 10px 0;
}

.card {
  min-height: 430px;
}

.new-notification-indicator {
  background: tomato;
  background-color: #ff1493;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  height: 16px;
  position: absolute;
  right: 12px;
  top: -4px;
  width: 18px;
  text-align: center;
}

.header-notification {
  width: 30vw !important;
  top: -2.5rem;
  padding-bottom: 2rem;
}

.active-item {
  display: block;
  font-weight: bold;
}

.modal {
  z-index: 9998;
}

.modal-content {
  max-height: 80vh;
  overflow-y: auto;
}

.modal-container {
  overflow: hidden;

}

tbody tr:nth-child(even) {
  border: 1px solid #DADAE7;
  border-radius: 7px !important;
  margin: 10px !important;
}

.active-status {
  color: #4EAB33;
  border: 1px solid #4EAB33;
  padding: 4px 1px;
  width: 70px;
  background-color: #F1FFED;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  border-radius: 4px !important;
}

.cancel-status {
  color: red;
  border: 1px solid red;
  padding: 4px 1px;
  width: 70px;
  background-color: #ffe1dd;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  border-radius: 4px !important;
}

.invite-user {
  width: 40% !important;
}

.btn-cancel {
  border: 1px solid #D9D9D9;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.permission {
  background-color: #F9FAFB;
  padding: 10px;
  width: 100%;
}

.accounttable th,
.locationtable th,
.transactiontable th,
.subscriptiontable th {
  text-align: center;
  border-block: 1px solid #EAECF0;
  background-color: #F9FAFB;
  color: rgba(111, 45, 168, 1);
}

.accounttable th:nth-child(1) {
  border-left: 1px solid #EAECF0;
  border-top-left-radius: 20px;
}

.subscriptiontable th:nth-child(1) {
  border-left: 1px solid #EAECF0;
  border-top-left-radius: 20px;
}

.transactiontable th:nth-child(1) {
  border-left: 1px solid #EAECF0;
  border-top-left-radius: 20px;
}

.transactiontable th:nth-child(8) {
  border-right: 1px solid #EAECF0;
  border-top-right-radius: 20px;
}

.accounttable th:nth-child(7) {
  border-right: 1px solid #EAECF0;
  border-top-right-radius: 20px;
}

.subscriptiontable th:nth-child(9) {
  border-right: 1px solid #EAECF0;
  border-top-right-radius: 20px;
}

.accounttable td,
.locationtable td,
.subscriptiontable td {
  text-align: center;
  border-block: 1px solid #DADAE7;
  color: #000;
}

.transactiontable td {
  text-align: center;
  border-block: 1px solid #DADAE7;
  color: #000;
  padding: 8px;
  text-align: left;
}

.locationtable th:nth-child(4) {
  border-right: 1px solid #EAECF0;
  border-top-right-radius: 20px;
}

.scrapstable th:nth-child(3) {
  border-right: 1px solid #EAECF0;
  border-top-right-radius: 20px;
}

.accounttable td:nth-child(1) {
  border-left: 1px solid #DADAE7;
}

.subscriptiontable td:nth-child(1) {
  border-left: 1px solid #DADAE7;
}

.transactiontable td:nth-child(1) {
  border-left: 1px solid #DADAE7;
}

.locationtable th:nth-child(1) {
  border-left: 1px solid #EAECF0;
  border-top-left-radius: 20px;
}

.locationtable td:nth-child(1) {
  border-left: 1px solid #DADAE7;
}

.accounttable td:nth-child(7) {
  border-right: 1px solid #DADAE7;
  justify-content: center;
}

.transactiontable td:nth-child(8) {
  border-right: 1px solid #DADAE7;
  justify-content: center;
}

.subscriptiontable td:nth-child(9) {
  border-right: 1px solid #DADAE7;
  justify-content: center;
}

.locationtable td:nth-child(4) {
  border-right: 1px solid #DADAE7;
  justify-content: center;
}


table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.table-footer {
  background-color: rgb(245, 234, 252);
  border-radius: 0px 0px 12px 12px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.active-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #27AE60;
  padding: 5px;
}

.plan-container {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
}

.bottom-add-scrap {
  width: 100%;
  height: 8vh;
  background-color: #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px -1px 1px 0px rgba(0, 0, 0, 0.03), 0px -3px 2px 0px rgba(0, 0, 0, 0.02), 0px -6px 2px 0px rgba(0, 0, 0, 0.01), 0px -9px 3px 0px rgba(0, 0, 0, 0.00);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  width: 100px;
  height: 20px;
  border-radius: 25px;
  overflow: hidden;
}

.main-progress-bar {
  width: 100px;
  height: 20px;
  border-radius: 25px;
  background-color: rgba(234, 236, 240, 1);
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 16px;
  user-select: none;
}

@media screen and (max-width: 1400px) {

  .aside-list-open {
    background-color: #25174d;
    width: 20%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }

}

.pv-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}


@media screen and (max-width: 1050px) {

  .overlay .opne-asid {
    padding: 20px;
    position: fixed;
  }

  .aside-list-open {
    background-color: #25174d;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }

  .header-notification {
    width: 95vw !important;
    top: -2.5rem;
    left: 10px;
    padding-bottom: 2rem;
  }

  .aside-list {
    display: none;
  }

  .overlay section {
    padding: 10px;
  }
}

.tabs {
  padding: 15px;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

button {
  border: none;
}

.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}

.content p {
  width: 100%;
  height: 100%;
}

.tabs {
  border: none;
  width: 100%;
  padding: 0px;
  margin: 0;
  background-color: #fff;
}

.tab {
  width: 23%;
  padding: 0;
}

.tableHover:hover {
  cursor: pointer;
  background: #ececec !important;
  color: #000 !important;
}

.raise-pickups-sidebar {
  width: 30%;
}

.countrol-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  color: #000;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: -1px 0px 7px #d4d4d4, 36px 36px 72px #ffffff;
  cursor: pointer;
  outline: none;
  border: none;
}

.time-line-page {
  width: 55vw;
}

.time-line {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.line {
  width: 2px;
  background-color: #717d96;
  height: 80px;
}

.time-line-box {
  background-color: #fff;
  border-radius: 5px;
  border-left: 4px solid #fff;
  box-shadow: 2px 2px 12px rgb(243, 243, 243);
  padding: 15px;
}

.time-line-left-step {
  width: 50vw;
}

.time-line-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.user-chip {
  background: #ececec;
  width: fit-content;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin: 8px 0 0 0;
}

.user-chip img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0px 5px 0 0;
}